import React from 'react';
import { Link } from 'gatsby';
import Image from 'reusecore/src/elements/Image';
import DocumentationSection from '../../../../../containers/SaasModern/Documentation';
import example_12_months from '../../../../../../../common/src/assets/image/example_12_months.png';
import template_options_12_months from '../../../../../../../common/src/assets/image/template_options_12_months.png';

const Documentation = () => {
  const content = (
    <div>
      <p>
        The 12 Months/Page template style generates 12 months to a single page.
        This is similar to the{' '}
        <Link to="/Documentation/Create-Calendar/Templates-and-Options/Year">
          Year template
        </Link>
        , except a starting month can be specified. Also, dates can include
        color-coding for up to 4 items, instead of only a single item.
      </p>
      <p>
        <Image alt="" src={example_12_months} style={{ width: 1016 }} />
      </p>
      <h2>Template Settings</h2>
      <p>
        The 12 Months/Page template provides the following options when creating a
        calendar...
      </p>
      <Image alt="" src={template_options_12_months} style={{ width: 448 }} />
      <br />
      <table className="table">
        <tbody>
          <tr>
            <td>
              <strong>Start month</strong>
            </td>
            <td>The first month to show in the generated output.</td>
          </tr>
          <tr>
            <td>
              <strong>First day of week</strong>
            </td>
            <td>The first day of the week will be the left-most day column.</td>
          </tr>
        </tbody>
      </table>
    </div>
  );

  return (
    <DocumentationSection
      url="https://www.printablecal.com/Documentation/Create-Calendar/Templates-and-Options/Month-12-Months-Page"
      commentsId="commentsplus_post_173_489"
      title="12 Months/Page Template"
      description="Generate 12 months to a single page. This is similar to the Year template, except a starting month can be specified. Also, dates can include color-coding for up to 4 items, instead of only a single item."
      keywords="12 month template, year template"
      content={content}
    />
  );
};

export default Documentation;
